import React from "react";
import { Layout, Menu, Button } from "antd";
import { UploadOutlined, DashboardOutlined, TableOutlined, LogoutOutlined, BookOutlined, DownloadOutlined } from '@ant-design/icons';
import "./dashboard.css";
import { AdminApi } from "../../api/AdminApi";
import { ApiConfig } from "../../config/ApiConfig";
import { RoshdBackUp } from "../ComponentsWithStates/Admin/modals/backup/RoshdBackUp";
const { Header, Content, Footer, Sider } = Layout;

export const adminWrapperComp = (Component: any, selectedMenu: any) => {
    let adminApi = new AdminApi(new ApiConfig({}));
    const logOut = () => {
        adminApi.logout().then(res =>
            window.location.href = "/login"
        );
    }

    const navigate = (menuInfo: any) => {
        selectedMenu = menuInfo.key.toString();
        if (menuInfo.key.toString() === "1") {
            window.location.href = "/admin/summary";
        } else if (menuInfo.key.toString() === "2") {
            window.location.href = "/admin";
        }
        else if (menuInfo.key.toString() === "3") {
            window.location.href = "/admin/certs";
        } else if (menuInfo.key.toString() === "4") {
            window.location.href = "/admin/archive";
        } else if (menuInfo.key.toString() === "5"){
            window.location.href = "/admin/backup";
        }

    }
    return function ConnectedComponent(props: any) {
        return (
            <Layout className="r_adminDashboard">
                <Sider
                    breakpoint="lg"
                    collapsedWidth="0"
                    onBreakpoint={broken => {
                        console.log(broken);
                    }}
                    onCollapse={(collapsed, type) => {
                        console.log(collapsed, type);
                    }}
                >
                    <div>
                        <img className="r_dashboard_Logo" src="https://roshduni.net/wp-content/uploads/2018/08/Rosh-Logo-Final-LR1.png" />
                    </div>
                    <Menu theme="dark" mode="inline" defaultSelectedKeys={[selectedMenu]} onClick={navigate} >
                    <Menu.Item key="1" icon={<DashboardOutlined />}>
                            Dashboard
                    </Menu.Item>
                    <Menu.Item key="2" icon={<TableOutlined />}>
                            List
                    </Menu.Item>
                    <Menu.Item key="3" icon={< UploadOutlined />}>
                            Ceritifacates
                    </Menu.Item>
                    <Menu.Item key="4" icon={< BookOutlined />}>
                            Archive
                    </Menu.Item>
                    <Menu.Item key="5" icon={< DownloadOutlined />}>
                            Back Up
                    </Menu.Item>
                    </Menu>
                </Sider>
                <Layout>
                    <Header className="site-layout-sub-header-background" style={{ padding: 0, textAlign: 'right' }}>
                        <Button type="link" onClick={logOut}><LogoutOutlined /> Logout</Button>
                    </Header>
                    <Content style={{ margin: '24px 16px 0' }}>
                        <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                            <Component {...props}/>
                        </div>
                    </Content>
                    <Footer style={{ textAlign: 'center' }}>Rosh Virtual University ©2020 Created by <a href="https://poxpen.com/" target="_blank">PoxPen</a></Footer>
                </Layout>
            </Layout>
        );
    }

}