import * as React from 'react';
import { Redirect, Route } from 'react-router-dom';
import LocalStorageService from '../../api/LocalStorageService';

export const PrivateRoute = ({ component: Component, ...rest } : any) => {
    return (
        <Route
            {...rest}
            render={props =>
                LocalStorageService.getAccessToken() !== null ?  (
                    <Component {...props} />
                ) : (
                        <Redirect  to={{
                            pathname: "/login",
                            state: { from: props.location }
                        }}/>
                    )
            }
        />)
}