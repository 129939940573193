import React from "react";
import { Table, Space, Form, Input, Button, Select, DatePicker, Row, Col, Dropdown, Menu, Spin, message, Pagination } from "antd";
import "./roshdData.css";
import Title from "antd/lib/typography/Title";
import { DownOutlined, FileExcelOutlined, PlusOutlined, DeleteOutlined, EditOutlined, DownloadOutlined } from "@ant-design/icons";
import { NewRecordModal } from "../modals/addNewRecord/addNewRecord";
import { ExcelModal } from "../modals/excel/excelModal";
import { CertificateTO } from "../../../../tos/CertificateTO";
import { FilteringCertificateTO } from "../../../../tos/FilteringCertificateTO";
import { AdminApi } from "../../../../api/AdminApi";
import { ApiConfig } from "../../../../config/ApiConfig";
import { DeleteCertTO } from "../../../../tos/DeleteCertTO";
import { FormInstance } from "antd/lib/form";
import { fileToObject } from "antd/lib/upload/utils";
import ExportJsonExcel from 'js-export-excel';
import { adminWrapperComp } from "../../../HOC/adminDashboard";
const { Column } = Table;
const { RangePicker } = DatePicker;

export interface IRoshdDataState {
    isNewRecordModalVisible: boolean;
    isExcelModalVisible: boolean;
    certificates: CertificateTO[];
    isLoading: boolean;
    errorMsg: string;
    totalNumberOfPages: number;
    totalNumberOfCertificate: number;
    pageSize: number;
    currentPage: number;
    dataToEdit: CertificateTO | undefined;
    isItEditMode: boolean,
    filteringObj: FilteringCertificateTO;
}

export interface IRoshdDataProps {
}

class RoshdData extends React.PureComponent<IRoshdDataProps, IRoshdDataState>  {

    adminApi = new AdminApi(new ApiConfig({}));
    formRef = React.createRef<FormInstance>();

    constructor(props: Readonly<IRoshdDataProps>) {
        super(props);
        this.resetSelectedRecord = this.resetSelectedRecord.bind(this);
        this.state = {
            isNewRecordModalVisible: false, isLoading: true, errorMsg: '', isExcelModalVisible: false, certificates: [],
            totalNumberOfPages: 0, totalNumberOfCertificate: 0, pageSize: 10, currentPage: 1,
            dataToEdit: {} as CertificateTO, isItEditMode: false,
            filteringObj: new FilteringCertificateTO(null, null, null, null, null, null, null, null, null)
        }
    }

    componentDidMount() {
        this.setState({ isLoading: true, errorMsg: '' });
        this.getCertificates();
    }

    getCertificates = () => {
        this.adminApi.getCertificates(new FilteringCertificateTO(null, null, null, null, null, null, null,
            this.state.currentPage,
            this.state.pageSize))
            .then(res => {
                this.setState({ totalNumberOfCertificate: res.numberOfCertificates });
                this.setState({ totalNumberOfPages: res.totalNumberOfPages });
                this.setState({ pageSize: res.pageSize });
                this.setState({ certificates: res.certificateTOList });
                this.setState({ isLoading: false, errorMsg: '' });
            }).catch(err =>
                this.setState({ isLoading: false, errorMsg: 'Something went worng, please try again later' })
            );
    }

    deleteItem(values: CertificateTO) {
        this.adminApi.deleteCertifcates(new DeleteCertTO([values.id])).then(res => {
            if (res.status === 200) {
                message.success('The record has been deleted successfully!');
                this.getCertificates();
            }
        }).catch(err =>
            message.error('Something went worng, please try again later')
        );
    }


    download(values: CertificateTO) {
        this.adminApi.downLoadCertificateAdmin(values.certificateId);
    }

    editItem = (to: CertificateTO) => {
        this.setState({ dataToEdit: to });
        this.setState({ isItEditMode: true, isNewRecordModalVisible: true });
    }

    exportExcel = () => {
        const data = this.state.certificates ? this.state.certificates : '';
        var option: any = {};
        let dataTable = [];
        if (data) {
            for (let i in data) {
                if (data) {
                    let obj = {
                        'Name': data[i].name,
                        'Arabic Name': data[i].arabicName,
                        'Email': data[i].email,
                        'Department': data[i].department,
                        'Certificate Id': data[i].certificateId,
                        'Graduation Year': data[i].graduationYear,
                    }
                    dataTable.push(obj);
                }
            }
        }
        option.fileName = 'Roshd Report'
        option.datas = [
            {
                sheetData: dataTable,
                sheetName: 'sheet',
                sheetFilter: ['Name', 'Arabic Name', 'Email', 'Department', 'Certificate Id', 'Graduation Year'],
                sheetHeader: ['Name', 'Arabic Name', 'Email', 'Department', 'Certificate Id', 'Graduation Year'],
            }
        ];

        var toExcel = new ExportJsonExcel(option);
        toExcel.saveExcel();

    }

    renderTable() {
        if (this.state.isLoading) {
            return <Spin size='large' />
        } else if (this.state.errorMsg != '') {
            return <p>{this.state.errorMsg}</p>
        }
        return (
            <div>
                <div style={{ textAlign: 'right', marginRight: '8%', marginBottom: 20 }}>
                    <Button onClick={() => this.exportExcel()}>Export to Excel</Button>
                </div>
                <Table
                    pagination={false}
                    dataSource={this.state.certificates}
                >
                    <Column title="Student Name" dataIndex="name" key="studentName" />
                    <Column title="Student Name" dataIndex="arabicName" key="studentName" />
                    <Column title="Student Email" dataIndex="email" key="studentEmail" />
                    <Column title="Department" dataIndex="department" key="department" />
                    <Column title="Graduation Year" dataIndex="graduationYear" key="graduationYear" />
                    <Column title="Certificate ID" dataIndex="certificateId" key="certificateID" />
                    <Column
                        title="Action"
                        key="action"
                        render={(text: any, record: any) => (
                            <Space size="middle">
                                <Button onClick={() => this.editItem(record)}><EditOutlined /></Button>
                                <Button onClick={() => this.download(record)}><DownloadOutlined /></Button>
                                <Button onClick={() => this.deleteItem(record)} danger={true}><DeleteOutlined /></Button>
                            </Space>
                        )}
                    />
                </Table>
                <Pagination
                    defaultCurrent={1}
                    pageSize={this.state.pageSize}
                    total={this.state.totalNumberOfCertificate}
                    current={this.state.currentPage}
                    showSizeChanger={true}
                    onChange={(pageNumber, pageSize) => this.pageChange(pageNumber, pageSize)}
                />
            </div>);
    }

    pageChange(pageNumber: number, pageSize: number | undefined) {
        let filter = this.state.filteringObj;
        filter.pageSize = pageSize === undefined ? null : pageSize;
        filter.pageNumber = pageNumber;
        this.setState({ currentPage: pageNumber });
        if (pageSize) {
            this.setState({ pageSize });
        }
        this.getCertificateWithFilter(filter);

    }

    onFinish = (values: any) => {
        let name = values.name === undefined || "" ? null : values.name;
        let arabicName = values.arabicName === undefined || "" ? null : values.arabicName;
        let email = values.email === undefined || "" ? null : values.email;
        let ceritifacteId = values.certificateId === undefined || "" ? null : values.certificateId;
        let departments = values.departments == undefined ? null : values.departments;
        let startYear = values.graduationYear == undefined ? null : values.graduationYear[0].year().toString();
        let endYear = values.graduationYear == undefined ? null : values.graduationYear[1].year().toString();
        let filteringObj = new FilteringCertificateTO(ceritifacteId, departments, email, startYear, endYear, name, arabicName, null, null);

        this.getCertificateWithFilter(filteringObj);

    };

    getCertificateWithFilter(filteringObj: FilteringCertificateTO) {
        this.setState({ isLoading: true, errorMsg: '', filteringObj: filteringObj });
        this.adminApi.getCertificates(filteringObj)
            .then(res => {
                this.setState({ totalNumberOfCertificate: res.numberOfCertificates });
                this.setState({ totalNumberOfPages: res.totalNumberOfPages });
                this.setState({ pageSize: res.pageSize });
                this.setState({ certificates: res.certificateTOList });
                this.setState({ isLoading: false, errorMsg: '' });
            }).catch(err =>
                this.setState({ isLoading: false, errorMsg: 'Something went worng, please try again later' })
            );
    }
    handleMenuClick = (e: any) => {
        if (e && e.key === "newEntry") {
            this.setState({ isNewRecordModalVisible: true, isItEditMode: false });
        } else if (e && e.key === "excel") {
            this.setState({ isExcelModalVisible: true });
        }
    }

    closeNewRecordModal = () => {
        this.setState({ isNewRecordModalVisible: false });
        this.updateData();
    }

    closeExcelModal = () => {
        this.setState({ isExcelModalVisible: false });
        this.updateData();
    }

    clearFilters() {
        this.formRef.current?.resetFields();
        this.setState({ filteringObj: new FilteringCertificateTO(null, null, null, null, null, null, null, null, null) });
        this.updateData();
    }

    updateData = () => {
        this.getCertificates();
    }

    showNewRecoder() {
        if (this.state.isNewRecordModalVisible) {
            return <NewRecordModal
                isItVisible={this.state.isNewRecordModalVisible}
                closeModal={this.closeNewRecordModal}
                updateData={this.updateData}
                isEditModeActive={this.state.isItEditMode}
                certificate={this.state.dataToEdit}
                resetSelectedData={this.resetSelectedRecord}
            />
        }
    }

    resetSelectedRecord() {
        this.setState({ dataToEdit: undefined });
    }

    render() {
        const children: any[] = [];
        return <>
            {this.showNewRecoder()}
            <ExcelModal isItVisible={this.state.isExcelModalVisible} closeModal={this.closeExcelModal} />
            <Row className="r_infoSection">
                <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                    <Title level={3} type="secondary">Search Section</Title>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} style={{ textAlign: 'right' }}>
                    <Dropdown overlay={<Menu onClick={this.handleMenuClick}>
                        <Menu.Item key="newEntry" icon={<PlusOutlined />}>
                            Add new entry
                        </Menu.Item>
                        <Menu.Item key="excel" icon={<FileExcelOutlined />}>
                            Import from Excel
                        </Menu.Item>
                    </Menu>}>
                        <Button>
                            Add new record(s) <DownOutlined />
                        </Button>
                    </Dropdown>
                </Col>
            </Row>
            <Form name="horizontal_search" onFinish={this.onFinish} ref={this.formRef}>
                <Row>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8} className="r_searchCol">
                        <Row style={{ paddingRight: '12.5%', paddingLeft: '12.5%' }}>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} style={{ paddingRight: '1%' }}>
                                <Form.Item name="name">
                                    <Input placeholder="Student Name (En)" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} style={{ paddingLeft: '1%' }}>
                                <Form.Item name="arabicName">
                                    <Input placeholder="Student Name (Ar)" />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8} className="r_searchCol">
                        <Form.Item name="email">
                            <Input placeholder="Student Email" className="r_searchInput" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8} className="r_searchCol">
                        <Form.Item name="departments">
                            <Select
                                className="r_searchInput"
                                mode="tags"
                                placeholder="Please select departments"
                                defaultValue={[]}
                            >
                                {children}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8} className="r_searchCol">
                        <Form.Item name="graduationYear">
                            <RangePicker picker="year" className="r_searchInput" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8} className="r_searchCol">
                        <Form.Item name="certificateId">
                            <Input placeholder="Certificate ID" className="r_searchInput" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8} className="r_searchCol">
                        <Space className="r_searchInput">
                            <Form.Item shouldUpdate={true}>
                                {() => (
                                    <Button onClick={() => this.clearFilters()}>
                                        Clear Filters
                                    </Button>
                                )}
                            </Form.Item>
                            <Form.Item shouldUpdate={true}>
                                {() => (
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                    >
                                        Search
                                    </Button>
                                )}
                            </Form.Item>
                        </Space>
                    </Col>
                </Row>
            </Form>
            <Row className="r_infoSection">
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                    <Title level={3} type="secondary">Results Section</Title>
                </Col>
            </Row>
            <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} className="r_dataTable">
                    {this.renderTable()}
                </Col>
            </Row>
        </>;
    }

}


const roshdData = adminWrapperComp(RoshdData, "2");
export { roshdData as RoshdData };
