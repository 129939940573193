import React from "react";
import { Modal } from "antd";
import { Upload, message } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { AdminApi } from "../../../../../api/AdminApi";
import { ApiConfig } from "../../../../../config/ApiConfig";
import BackendApi from '../../../../../config/BackendApi.json'
import LocalStorageService from "../../../../../api/LocalStorageService";
import { adminWrapperComp } from "../../../../HOC/adminDashboard";

const { Dragger } = Upload;


export interface IExcelModalState {
}

export interface IExcelModalProps {

}

class UploadCertificate extends React.PureComponent<IExcelModalProps, IExcelModalState>  {

    adminApi = new AdminApi(new ApiConfig({}));
    constructor(props: Readonly<IExcelModalProps>) {
        super(props);
    }


    sendExcelFile(info: any) {
        {
            const { status } = info.file;
            if (status !== 'uploading') {

            }
            if (status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        }
    }

    getToken() {
        let token = LocalStorageService.getAccessToken()
        return token == null ? '' : token;
    }

    dragProps = {
        accept: 'application/pdf',
        name: 'file',
        multiple: true,
        action: BackendApi.basicUrl + BackendApi.admin.upload,
        showUploadList: { showPreviewIcon: false, showRemoveIcon: false, showDownloadIcon: false },
        defaultFileList: [],
        headers: { "Authorization": this.getToken() },
        onChange: this.sendExcelFile.bind(this),

    };

    render() {
        return <>
            <Dragger {...this.dragProps}>
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>
                <p className="ant-upload-text">Upload Certificates here</p>
                <p className="ant-upload-hint">
                    Support for a single or bulk upload.
                </p>
            </Dragger>
        </>;
    }

}


const uploadCertificate = adminWrapperComp(UploadCertificate, "3");
export { uploadCertificate as UploadCertificate };
