import React from "react";
import { Form, Input, Button, Spin, Steps } from "antd";
import { UserTable } from "../../../StatelessComponents/UserTable/UserTable";
import { CertificateTO } from "../../../../tos/CertificateTO";
import { UserOutlined, SolutionOutlined, SmileOutlined } from "@ant-design/icons";
import ReactCodeInput from 'react-verification-code-input';
import "./studentUI.css";
import { StudentApi } from '../../../../api/StudentApi';
import { NotFoundException } from "../../../../exception/NotFoundException";
import { debug } from "console";
const { Step } = Steps;

export interface IStudentUIState {
    isTableVisible: boolean;
    isLoading: boolean;
    certifacates: CertificateTO[] | any;
    errorMessage: string;
    currentState: number;
    email: string;
    code: string;
}

export interface IStudentUIProps {
}

class StudentUI extends React.PureComponent<IStudentUIProps, IStudentUIState>  {

    studentApi = new StudentApi({});

    constructor(props: Readonly<IStudentUIProps>) {
        super(props);
        this.state = { isTableVisible: false, isLoading: false, certifacates: undefined, errorMessage: "", currentState: 0, email: '', code: '' }

    }

    onSubmittingEmail = (values: any) => {
        debugger;
        let email = values.email;
        this.setState({ isLoading: true });
        this.setState({ errorMessage: "" });
        this.studentApi.getCode(email).then(res => {
            this.setState({ isLoading: false });
            this.setState({ currentState: 1, email });
        }).catch(err => {
            this.setState({ isLoading: false });
            if (err instanceof NotFoundException) {
                this.setState({ errorMessage: 'الرجاء التأكد من البريد الألكتروني' });
                return;
            }
            this.setState({ errorMessage: 'خطأ تقني الرجاء المحاولة لاحقا' });
        });
    };

    onSubmittingCode = (code: string) => {
        this.setState({ isLoading: true });
        this.studentApi.getStudentCertificates(this.state.email, code).then(res => {
            this.setState({ certifacates: res });
            this.setState({ isLoading: false, isTableVisible: true, code: code, currentState: 2 });
        }).catch(err => {
            this.setState({ isLoading: false, isTableVisible: false, certifacates: [], currentState: 0 });
            this.setState({ errorMessage: 'الرجاء التأكد من البريد الالكتروني والكود' });
        });

    }

    renderSearchResult() {
        if (this.state.isLoading) {
            return (
                <div style={{ textAlign: 'center' }}>
                    <span>
                        <Spin size="large" />
                    </span>
                </div>
            );
        }
        if (this.state.isTableVisible) {
            return <UserTable
                email={this.state.email}
                code={this.state.code}
                certifacate={this.state.certifacates}
                isActionButtonAvailable={true} />
        }
        if (this.state.errorMessage !== "") {
            return (
                <div style={{ textAlign: 'center' }}>
                    <span> <h4> {this.state.errorMessage}</h4> </span>
                </div>
            )
        }
    }

    render() {
        return <>
            <Steps current={this.state.currentState}>
                <Step key="2" status={this.state.currentState < 2 ? "wait" : "finish"} title="الشهادات" icon={<SmileOutlined />} />
                <Step key="1" status={this.state.currentState < 1 ? "wait" : "finish"} title="التحقق" icon={<SolutionOutlined />} />
                <Step key="0" status={this.state.currentState < 0 ? "wait" : "finish"} title="تسجيل الدخول" icon={<UserOutlined />} />
            </Steps>
            {this.state.currentState === 0 &&
                <div className="steps-action">
                    <Form name="student_search" onFinish={this.onSubmittingEmail} style={{ paddingRight: '20%', paddingLeft: '20%', textAlign: 'right' }}>
                        <span className="r_userInputSpan">:يرجى ادخال بريدكم الالكتروني</span>
                        <Form.Item name="email" rules={[{ required: true, message: 'يرجى إدخال البريد الإلكتروني، للحصول على رمز التحقق' }]}>
                            <Input type="email" />
                        </Form.Item>
                        <Form.Item>
                            <div style={{ textAlign: 'center' }}>
                                <Button type="primary" htmlType="submit" loading={this.state.isLoading}>
                                    التالي
                                </Button>
                            </div>
                        </Form.Item>
                    </Form>
                </div>
            }
            {this.state.currentState === 1 &&
                <div className="steps-action" style={{ textAlign: 'center' }}>
                    <span className="r_userInputSpan" style={{marginBottom:20}}>تم ارسال كود التحقق الى بريدكم الالكتروني. يرجى نسخ و لصق الكود في المكان المخصص ادناه</span>
                    <ReactCodeInput onComplete={this.onSubmittingCode} type="text" fields={8} />
                </div>
            }
            {this.state.currentState === 2 &&
                this.renderSearchResult()
            }
            {this.state.errorMessage !== "" &&
                <div style={{ textAlign: 'center' }}>
                    <span> <h4> {this.state.errorMessage}</h4> </span>
                </div>
            }
        </>;
    }

}


const studentUI = StudentUI;
export { studentUI as StudentUI };
