import axios from "axios";
import BackendApi from '../config/BackendApi.json';
import LocalStorageService from "./LocalStorageService";
import { ApiConfig } from "../config/ApiConfig";
let isTokenRefreshing = false;
let failedQueue = [] as any;

export const interceptorOverride = (apiInterceptor:any) => {
// LocalstorageService
const localStorageService = LocalStorageService.getService();

const processQueue = (error: any, token = null) => {
    failedQueue.forEach((promise: { reject: (arg0: any) => void; resolve: (arg0: null) => void; }) => {
        if (error) {
            promise.reject(error);
        } else {
            promise.resolve(token);
        }
    })
    failedQueue = [];
}

// Add a request interceptor
apiInterceptor.request.use(
    (config: any) => {
        const token = localStorageService.getAccessToken();
        if (token) {
            config.headers['Authorization'] = token;
        }
        return config;
    },
    (error: any) => {
        Promise.reject(error)
    });

//Add a response interceptor
apiInterceptor.response.use((response:any) => {
    return response
}, function (error:any) {
    const originalRequest = error.config;

    if (!error.response) {
        return Promise.reject(error);
    }

    if (error.response.status === 401 && originalRequest.url ===  BackendApi.basicUrl + 'login') {
        return Promise.reject(error);
    }

    if (error.response.status === 401 && !originalRequest._retry) {
        // Starting to try refresh token
        originalRequest._retry = true;
        if (isTokenRefreshing === false) {
            // Token refreshing.
            isTokenRefreshing = true;
            let refreshToken = localStorageService.getRefreshToken();
            return axios.post( BackendApi.basicUrl+ 'refreshToken',
                {
                    "refreshToken": refreshToken
                }, new ApiConfig({}) )
                .then(res => {
                    // Token refreshed.
                    isTokenRefreshing = false;
                    if (res.status === 200) {                       
                        localStorageService.setToken(res.data);
                        axios.defaults.headers.common['Authorization'] = localStorageService.getAccessToken();
                        originalRequest.headers.Authorization = localStorageService.getAccessToken();
                        processQueue(null, res.data.token);
                        return axios(originalRequest);
                    } else {
                        console.log("Got error while trying refresh token. Redirecting to login.")
                        localStorageService.clearToken();
                        window.location.href = '/login';
                    }
                }).catch(err=>{
                    console.log("Got error while trying refresh token. Redirecting to login.")
                    localStorageService.clearToken();
                    window.location.href = '/login';
                });
        } else {
            // Token already refreshing, requests adding to queue.
            return new Promise(function (resolve, reject) {
                failedQueue.push({ resolve, reject })
            }).then(token => {
                originalRequest.headers['Authorization'] =token;
                return axios(originalRequest);
            }).catch(err => {
                return Promise.reject(err);
            })
        }
    }

    if (error.response.status === 401 && error.config.url.indexOf('refreshtoken') > -1) {
        localStorageService.clearToken();
        window.location.href = '/login';
    }
    return Promise.reject(error);
});
}