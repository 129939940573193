import React from "react";
import { Layout, Tabs, Row, Col } from "antd";
import Footer from "../../Components/StatelessComponents/Footer/Footer";
import Header from "../../Components/StatelessComponents/Header/Header";
import "./homePage.css";
import { EmployerUI } from "../../Components/ComponentsWithStates/User/employer/employerUI";
import { StudentUI } from "../../Components/ComponentsWithStates/User/student/studentUI";
const { TabPane } = Tabs;

export interface IHomePageState {}

export interface IHomePageProps {
  location: any;
}

class HomePage extends React.PureComponent<IHomePageProps, IHomePageState> {
  tabChange = (key: string) => {
    console.log(key);
  };

  render() {
    return (
      <>
        <Layout className="layout r_mainLayout">
          <Header></Header>
          <div className="r_mainImg">
            <img src="imgs/mainImg.jpg" />
          </div>
          <Layout.Content>
            <div className="site-layout-content" style={{ marginBottom: "3%" }}>
              <Row>
                <Col xs={0} sm={0} md={0} lg={0} xl={3} xxl={3}></Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={18} xxl={18}>
                  <Tabs
                    defaultActiveKey="2"
                    onChange={this.tabChange}
                    className="r_mainTabs"
                  >
                    <TabPane tab="للتحقق من الشهادة" key="1">
                      <EmployerUI />
                    </TabPane>
                    <TabPane tab="للتحقق من الشهادة من قبل الطالب" key="2">
                      <StudentUI />
                    </TabPane>
                  </Tabs>
                </Col>
                <Col xs={0} sm={0} md={0} lg={0} xl={3} xxl={3}></Col>
              </Row>
            </div>
          </Layout.Content>
          <Footer></Footer>
        </Layout>
      </>
    );
  }
}

const homePage = HomePage;
export { homePage as HomePage };
