import React from "react";
import { Row, Card, Col, Table } from "antd";
import { Tabs } from 'antd';
import { AdminApi } from "../../../../../api/AdminApi";
import { ApiConfig } from "../../../../../config/ApiConfig";
import { DepartmentCount } from "../../../../../tos/DepartmentCount";
import { CodeSentCount } from "../../../../../tos/CodeSentCount";
import { EmployerRequestCount } from "../../../../../tos/EmployerRequestCount";
import { BarChart, CartesianGrid, XAxis, YAxis, Bar, Tooltip, Legend, PieChart, Pie } from 'recharts';
import Column from "antd/lib/table/Column";
import { adminWrapperComp } from "../../../../HOC/adminDashboard";
import { CertificateTO } from "../../../../../tos/CertificateTO";
import { ArchiveTO } from "../../../../../tos/ArchiveTO";

const { TabPane } = Tabs;

export interface IRoshdArchivetate {
  certificateTOs: ArchiveTO[];
}

export interface IRoshdArchiveProps {

}

class RoshdArchive extends React.PureComponent<IRoshdArchiveProps,IRoshdArchivetate>  {

  adminApi = new AdminApi(new ApiConfig({}));
  constructor(props: Readonly<IRoshdArchivetate>) {
    super(props);
    this.state = {certificateTOs:[]};
  }


  componentDidMount() {
    this.adminApi.getArchive().then(
      res => {
        this.setState({ certificateTOs: res });
      }
    );
  }





  render() {


    return (
      <Table dataSource={this.state.certificateTOs}>
      <Table.Column title="رقم الشهادة" dataIndex="certificateId" key="certificateId" />
      <Table.Column title="سنة التخرج" dataIndex="graduationYear" key="graduationYear" />
      <Table.Column title="كلية" dataIndex="department" key="department" />
      <Table.Column title="البريد الإلكتروني للطالب" dataIndex="email" key="studentEmail" />
      <Table.Column title="اسم الطالب بالعربية" dataIndex="arabicName" key="arabicName" />
      <Table.Column title="اسم الطالب باللغة الإنجليزية" dataIndex="name" key="studentName" />
      <Table.Column title="تاريخ الحذف" dataIndex="deleted" key="deleted" />
  </Table>
    );


  }


}


const roshdArchive = adminWrapperComp(RoshdArchive, "4");
export { roshdArchive as RoshdArchive };
