import React from "react";
import { Modal, List } from "antd";
import "./addNewRecord.css";


export interface IUploadResultTableState {

}

export interface IUploadResultTableProps {

    certificateIds: string[]
}

class UploadResultTable extends React.PureComponent<IUploadResultTableProps, IUploadResultTableState>  {



    constructor(props: IUploadResultTableProps) {
        super(props);
    }



    render() {
        return <>

                <List
                    header={<div>Failed to save the following certificates</div>}
                    bordered
                    dataSource={this.props.certificateIds}
                    renderItem={item => (
                        <List.Item>
                            {item}
                        </List.Item>
                    )}
                />
        
        </>;
    }

}


const uploadResultTable = UploadResultTable;
export { uploadResultTable as UploadResultTable };
