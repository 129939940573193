import BackendApi from './BackendApi.json';

export class ApiConfig  {
    returnRejectedPromiseOnError: boolean;
    timeout: number;
    baseURL: string;
    headers: any;

    constructor(header: any){
        this.returnRejectedPromiseOnError = true;
        this.timeout = 30000;
        this.baseURL = BackendApi.basicUrl;
        this.headers = header;
    }
}