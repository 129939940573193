
import React from 'react';
import { Layout, Menu } from 'antd';
import "./Header.css";
import { DownOutlined } from '@ant-design/icons/lib/icons';

const Header = () => {
    return (
        <Layout.Header className="r_headerWrapper">
            <Menu mode="horizontal" className="r_menu">
                <Menu.Item key="mail" style={{ float: 'right' }} className="logo">
                    <img src="https://roshduni.net/wp-content/uploads/2018/08/Rosh-Logo-Final-LR1.png" alt="Roshd University" width="250" />
                </Menu.Item>
                <Menu.SubMenu title="المزيد" style={{marginLeft:'11%',paddingTop:8}}>
                    <Menu.SubMenu title="الدفع">
                        <Menu.Item><a href="https://roshduni.net/ar/%d8%a7%d9%84%d8%ad%d8%b3%d8%a7%d8%a8%d8%a7%d8%aa-%d8%a7%d9%84%d8%a8%d9%86%d9%83%d9%8a%d8%a9/">الحسابات البنكية</a></Menu.Item>
                        <Menu.Item><a href="https://roshduni.net/ar/%d8%a7%d9%84%d8%a8%d8%b7%d8%a7%d9%82%d8%a9-%d8%a7%d9%84%d8%a7%d8%a6%d8%aa%d9%85%d8%a7%d9%86%d9%8a%d8%a9/">البطاقة الائتمانية</a></Menu.Item>
                    </Menu.SubMenu>
                    <Menu.Item><a href="https://roshduni.net/ar/%d9%88%d8%b3%d8%a7%d8%a6%d9%84-%d8%a7%d9%84%d8%aa%d9%88%d8%a7%d8%b5%d9%84-%d8%a7%d9%84%d8%a7%d8%ac%d8%aa%d9%85%d8%a7%d8%b9%d9%8a/">وسائل التواصل الاجتماعي</a></Menu.Item>
                    <Menu.Item><a href="https://roshduni.net/ar/faqs/">الأسئلة الشائعة</a></Menu.Item>
                    <Menu.Item><a href="//roshduni.net/ar/events/">الاحداث</a></Menu.Item>
                    <Menu.Item><a href="https://roshduni.net/ar/%d9%82%d8%b1%d8%a7%d8%b1%d8%a7%d8%aa-%d8%a7%d8%af%d8%a7%d8%b1%d9%8a%d8%a9/">قرارات ادارية</a></Menu.Item>
                </Menu.SubMenu>
                <Menu.Item><a href="https://roshduni.net/ar/contact/">اتصل بنا</a></Menu.Item>
                <Menu.Item><a href="https://roshduni.net/ar/%d8%af%d9%88%d8%b1%d8%a7%d8%aa-%d8%b1%d8%b4%d8%af/">دورات رشد</a></Menu.Item>
                <Menu.Item><a href="https://roshduni.net/ar/roshd-packages/">باقات رشد</a></Menu.Item>
                <Menu.SubMenu title="المدرسة الرقمية">
                    <Menu.Item><a href="https://roshduni.net/ar/%d8%a7%d9%84%d8%b5%d9%81-%d8%a7%d9%84%d8%b3%d8%a7%d8%a8%d8%b9/">الصف السابع</a></Menu.Item>
                    <Menu.Item><a href="https://roshduni.net/ar/%d8%a7%d9%84%d8%b5%d9%81-%d8%a7%d9%84%d8%ab%d8%a7%d9%85%d9%86/">الصف الثامن</a></Menu.Item>
                    <Menu.Item><a href="https://roshduni.net/ar/%d8%a7%d9%84%d8%b5%d9%81-%d8%a7%d9%84%d8%aa%d8%a7%d8%b3%d8%b9/">الصف التاسع</a></Menu.Item>
                    <Menu.Item><a href="https://roshduni.net/ar/%d8%a7%d9%84%d8%b5%d9%81-%d8%a7%d9%84%d8%b9%d8%a7%d8%b4%d8%b1/">الصف العاشر</a></Menu.Item>
                    <Menu.Item><a href="https://roshduni.net/ar/%d8%a7%d9%84%d8%b5%d9%81-%d8%a7%d9%84%d8%ad%d8%a7%d8%af%d9%8a-%d8%b9%d8%b4%d8%b1/">الصف الحادي عشر</a></Menu.Item>
                </Menu.SubMenu>
                <Menu.SubMenu title="المنح">
                    <Menu.Item><a href="https://roshduni.net/ar/%d8%a8%d8%b1%d9%86%d8%a7%d9%85%d8%ac-%d8%a7%d9%84%d9%85%d9%86%d8%ad-%d8%a7%d9%84%d8%b9%d8%a7%d9%85/">برنامج المنح العام</a></Menu.Item>
                    <Menu.Item><a href="https://roshduni.net/ar/%d9%85%d9%86%d8%ad%d8%a9-%d8%a7%d9%84%d9%85%d8%aa%d9%81%d9%88%d9%82%d9%8a%d9%86-%d9%81%d9%8a-%d8%a7%d9%84%d8%ab%d8%a7%d9%86%d9%88%d9%8a%d8%a9-%d8%a7%d9%84%d8%b9%d8%a7%d9%85%d8%a9/">منحة المتفوقين في الثانوية العامة</a>
                    </Menu.Item>
                    <Menu.Item><a href="https://roshduni.net/ar/%d9%85%d9%86%d8%ad%d8%a9-%d8%a7%d9%84%d9%85%d8%b9%d9%84%d9%85%d9%8a%d9%86/">منحة المعلمين</a>
                    </Menu.Item>
                    <Menu.Item><a href="https://roshduni.net/ar/%d9%85%d9%86%d8%ad%d8%a9-%d8%b7%d9%84%d8%a7%d8%a8-%d8%b1%d8%b4%d8%af-%d8%a7%d9%84%d8%a7%d9%81%d8%aa%d8%b1%d8%a7%d8%b6%d9%8a%d8%a9/">منحة طلاب رشد الافتراضية</a>
                    </Menu.Item>
                    <Menu.Item><a href="https://roshduni.net/ar/%d9%85%d9%86%d8%ad%d8%a9-%d8%ae%d8%b1%d9%8a%d8%ac%d9%8a-%d8%b1%d8%b4%d8%af-%d8%a7%d9%84%d8%a7%d9%81%d8%aa%d8%b1%d8%a7%d8%b6%d9%8a%d8%a9/">منحة خريجي رشد الافتراضية</a>
                    </Menu.Item>
                    <Menu.Item><a href="https://roshduni.net/ar/%d9%85%d9%86%d8%ad%d8%a9-%d8%a7%d9%84%d8%b9%d8%a7%d9%85%d9%84%d9%8a%d9%86-%d9%81%d9%8a-%d8%a7%d9%84%d8%ad%d9%83%d9%88%d9%85%d8%a9-%d8%a7%d9%84%d8%b3%d9%88%d8%b1%d9%8a%d8%a9-%d8%a7%d9%84%d9%85%d8%a4/">منحة العاملين في الحكومة السورية المؤقتة</a>
                    </Menu.Item>
                    <Menu.Item><a href="https://roshduni.net/ar/%d9%85%d9%86%d8%ad%d8%a9-%d8%b0%d9%88%d9%8a-%d8%a7%d9%84%d8%b4%d9%87%d8%af%d8%a7%d8%a1/">منحة ذوي الشهداء</a>
                    </Menu.Item>
                    <Menu.Item><a href="https://roshduni.net/ar/%d9%85%d9%86%d8%ad%d8%a9-%d8%b0%d9%88%d9%8a-%d8%a7%d9%84%d9%85%d8%b9%d8%aa%d9%82%d9%84%d9%8a%d9%86/">منحة ذوي المعتقلين</a>
                    </Menu.Item>
                </Menu.SubMenu>
                <Menu.SubMenu title="القبول والتسجيل">
                    <Menu.Item><a href="https://roshduni.net/ar/ourservices/">خدماتنا</a></Menu.Item>
                    <Menu.Item><a href="https://roshduni.net/ar/complaintsandsuggestionssystem/">نظام الشكاوي والمقترحات</a></Menu.Item>
                    <Menu.Item><a href="https://roshduni.net/ar/ourservices-2/">حقوق و واجبات</a></Menu.Item>
                    <Menu.Item><a href="https://roshduni.net/ar/gradingsystem/">نظام الدرجات</a></Menu.Item>
                    <Menu.Item><a href="https://roshduni.net/ar/exams/">الامتحانات</a></Menu.Item>
                    <Menu.Item><a href="https://roshduni.net/ar/scholarship/">المنح</a></Menu.Item>
                    <Menu.Item><a href="https://roshduni.net/ar/registration/">التسجيل</a></Menu.Item>
                </Menu.SubMenu>
                <Menu.SubMenu title="البرامج">
                    <Menu.SubMenu title="برامج رشد الافتراضية">
                        <Menu.Item><a href="https://roshduni.net/ar/dbs/">دبلوم العلوم السياسية</a></Menu.Item>
                        <Menu.Item><a href="https://roshduni.net/ar/dba/">دبلوم ادارة الاعمال</a></Menu.Item>
                        <Menu.Item><a href="https://roshduni.net/ar/bga/">بكالوريوس الإدارة الحكومية</a></Menu.Item>
                        <Menu.Item><a href="https://roshduni.net/ar/%d8%af%d8%a8%d9%84%d9%88%d9%85-%d8%a7%d9%84%d8%a7%d8%af%d8%a7%d8%b1%d8%a9-%d8%a7%d9%84%d8%ad%d9%83%d9%88%d9%85%d9%8a%d8%a9/">دبلوم الادارة الحكومية</a></Menu.Item>
                        <Menu.Item><a href="https://roshduni.net/ar/bit/">دبلوم تقنية المعلومات</a></Menu.Item>
                        <Menu.Item><a href="https://roshduni.net/ar/deh/">دبلوم التأهيل التربوي</a></Menu.Item>
                        <Menu.Item><a href="https://roshduni.net/ar/dac/">دبلوم المحاسبة</a></Menu.Item>
                        <Menu.Item><a href="https://roshduni.net/ar/resl/">برنامج اللغة الانكليزية كلغة ثانية</a></Menu.Item>
                    </Menu.SubMenu>
                    <Menu.SubMenu title="برامج جامعة اسيا">
                        <Menu.SubMenu title="برامج الدكتوراة">
                            <Menu.Item><a href="https://roshduni.net/ar/%d8%af%d9%83%d8%aa%d9%88%d8%b1%d8%a7%d9%87-%d8%a7%d9%84%d8%b9%d9%84%d9%88%d9%85-%d8%a7%d9%84%d8%a7%d9%86%d8%b3%d8%a7%d9%86%d9%8a%d9%87-%d8%ac%d8%a7%d9%85%d8%b9%d9%87-%d8%a7%d8%b3%d9%8a%d8%a7/">دكتوراه (العلوم الانسانيه)-جامعه اسيا</a>
                            </Menu.Item>
                            <Menu.Item>	<a href="https://roshduni.net/ar/%d8%af%d9%83%d8%aa%d9%88%d8%b1%d8%a7%d9%87-%d9%81%d9%8a-%d8%a3%d8%af%d8%a7%d8%b1%d9%87-%d8%a7%d9%84%d8%a7%d8%b9%d9%85%d8%a7%d9%84-%d8%ac%d8%a7%d9%85%d8%b9%d9%87-%d8%a7%d8%b3%d9%8a%d8%a7/">دكتوراه في أداره الاعمال-جامعه اسيا</a>
                            </Menu.Item>
                        </Menu.SubMenu>
                        <Menu.SubMenu title="برامج الماستر">
                            <Menu.Item><a href="https://roshduni.net/ar/%d9%85%d8%a7%d8%ac%d8%b3%d8%aa%d9%8a%d8%b1-%d8%a7%d9%84%d8%aa%d8%b1%d8%a8%d9%8a%d8%a9-%d8%ac%d8%a7%d9%85%d8%b9%d9%87-%d8%a7%d8%b3%d9%8a%d8%a7/">ماجستير التربية-جامعه اسيا</a>
                            </Menu.Item>
                            <Menu.Item><a href="https://roshduni.net/ar/%d9%85%d8%a7%d8%ac%d8%b3%d8%aa%d9%8a%d8%b1-%d9%81%d9%8a-%d8%a3%d8%af%d8%a7%d8%b1%d9%87-%d8%a7%d9%84%d8%a7%d8%b9%d9%85%d8%a7%d9%84-%d8%ac%d8%a7%d9%85%d8%b9%d9%87-%d8%a7%d8%b3%d9%8a%d8%a7/">ماجستير في أداره الاعمال-جامعه اسيا</a>
                            </Menu.Item>
                            <Menu.Item><a href="https://roshduni.net/ar/%d9%85%d8%a7%d8%ac%d8%b3%d8%aa%d9%8a%d8%b1-%d9%81%d9%8a-%d8%a3%d8%af%d8%a7%d8%b1%d9%87-%d8%a7%d9%84%d9%85%d9%88%d8%a7%d8%b1%d8%af-%d8%a7%d9%84%d8%a8%d8%b4%d8%b1%d9%8a%d8%a9-%d8%ac%d8%a7%d9%85%d8%b9/">ماجستير في أداره الموارد البشرية-جامعه اسيا</a>
                            </Menu.Item>
                            <Menu.Item><a href="https://roshduni.net/ar/%d9%85%d8%a7%d8%ac%d8%b3%d8%aa%d9%8a%d8%b1-%d9%81%d9%8a-%d8%a3%d8%af%d8%a7%d8%b1%d9%87-%d8%aa%d9%83%d9%86%d9%88%d9%84%d9%88%d8%ac%d9%8a%d8%a7-%d8%a7%d9%84%d9%85%d8%b9%d9%84%d9%88%d9%85%d8%a7%d8%aa-am/">ماجستير في أداره تكنولوجيا المعلومات &amp; الاتصالات-جامعه اسيا</a>
                            </Menu.Item>
                            <Menu.Item><a href="https://roshduni.net/ar/%d9%85%d8%a7%d8%ac%d8%b3%d8%aa%d9%8a%d8%b1-%d9%81%d9%8a-%d8%a7%d9%84%d8%a7%d8%af%d8%a7%d8%b1%d9%87-%d8%ac%d8%a7%d9%85%d8%b9%d9%87-%d8%a7%d8%b3%d9%8a%d8%a7/">ماجستير في الاداره-جامعه اسيا</a>
                            </Menu.Item>
                            <Menu.Item>	<a href="https://roshduni.net/ar/%d9%85%d8%a7%d8%ac%d8%b3%d8%aa%d9%8a%d8%b1-%d9%81%d9%8a-%d8%a7%d9%84%d8%b9%d9%84%d9%88%d9%85-%d8%a7%d9%84%d8%a7%d8%af%d8%a7%d8%b1%d9%87-%d8%ac%d8%a7%d9%85%d8%b9%d9%87-%d8%a7%d8%b3%d9%8a%d8%a7/">ماجستير في العلوم (الاداره)-جامعه اسيا</a>
                            </Menu.Item>
                        </Menu.SubMenu>
                        <Menu.SubMenu title="برامج البكالوريوس">
                            <Menu.Item><a href="https://roshduni.net/ar/bachelor-of-business-administration-hons/">بكالوريوس ادارة الاعمال – جامعة اسيا</a>
                            </Menu.Item>
                            <Menu.Item><a href="https://roshduni.net/ar/%d8%a8%d9%83%d8%a7%d9%84%d9%88%d8%b1%d9%8a%d9%88%d8%b3-%d8%a7%d9%84%d8%aa%d8%b1%d8%a8%d9%8a%d8%a9-%d8%aa%d8%b9%d9%84%d9%8a%d9%85-%d8%a7%d9%84%d8%b7%d9%81%d9%88%d9%84%d8%a9-%d8%a7%d9%84%d9%85%d8%a8/">بكالوريوس التربية (تعليم الطفولة المبكرة)-جامعه اسيا</a>
                            </Menu.Item>
                            <Menu.Item>	<a href="https://roshduni.net/ar/%d8%a8%d9%83%d8%a7%d9%84%d9%88%d8%b1%d9%8a%d9%88%d8%b3-%d9%81%d9%8a-%d8%aa%d9%83%d9%86%d9%88%d9%84%d9%88%d8%ac%d9%8a%d8%a7-%d8%a7%d9%84%d9%85%d8%b9%d9%84%d9%88%d9%85%d8%a7%d8%aa-%d8%a7%d9%84/">بكالوريوس في تكنولوجيا المعلومات &amp; الاتصالات -جامعه اسيا</a>
                            </Menu.Item>
                        </Menu.SubMenu>
                        <Menu.SubMenu title="برامج الدبلوم">
                            <Menu.Item><a href="https://roshduni.net/ar/%d8%af%d8%a8%d9%84%d9%88%d9%85-%d8%a7%d9%84%d8%af%d8%b1%d8%a7%d8%b3%d8%a7%d8%aa-%d8%a7%d9%84%d8%b9%d9%84%d9%8a%d8%a7-%d9%81%d9%8a-%d8%a3%d8%af%d8%a7%d8%b1%d9%87-%d8%a7%d9%84%d8%a7%d8%b9%d9%85%d8%a7/">دبلوم الدراسات العليا في أداره الاعمال-جامعه اسيا</a>
                            </Menu.Item>
                            <Menu.Item>	<a href="https://roshduni.net/ar/%d8%af%d8%a8%d9%84%d9%88%d9%85-%d8%a7%d9%84%d8%af%d8%b1%d8%a7%d8%b3%d8%a7%d8%aa-%d8%a7%d9%84%d8%b9%d9%84%d9%8a%d8%a7-%d9%81%d9%8a-%d8%a7%d9%84%d8%aa%d8%b9%d9%84%d9%8a%d9%85-%d9%81%d9%8a-%d9%85%d8%b1/">دبلوم الدراسات العليا في التعليم في مرحله الطفولة المبكرة-جامعه اسيا الكترونيه</a>
                            </Menu.Item>
                        </Menu.SubMenu>
                    </Menu.SubMenu>
                </Menu.SubMenu>
                <Menu.SubMenu title="حول">
                    <Menu.SubMenu title="جامعة رشد الافتراضية">
                        <Menu.Item><a href="https://roshduni.net/ar/about-us/">جامعة رشد الافتراضية</a></Menu.Item>
                        <Menu.Item>	<a href="https://roshduni.net/ar/%d9%81%d9%8a%d8%af%d9%8a%d9%88%d9%87%d8%a7%d8%aa-%d8%ad%d9%88%d9%84-%d8%b1%d8%b4%d8%af/">فيديوهات حول رشد</a></Menu.Item>
                        <Menu.Item><a href="https://roshduni.net/ar/become-a-teacher/">كن محاضرا</a></Menu.Item>
                    </Menu.SubMenu>
                    <Menu.SubMenu title="رشد أكاديمي">
                        <Menu.Item>
                            <a href="https://roshduni.net/ar/%d8%ad%d9%88%d9%84-%d8%a3%d9%83%d8%a7%d8%af%d9%8a%d9%85%d9%8a%d8%a9-%d8%b1%d8%b4%d8%af/">حول أكاديمية رشد</a>
                        </Menu.Item>
                    </Menu.SubMenu>
                    <Menu.SubMenu title="الشركاء">
                        <Menu.SubMenu title="جامعة اسيا">
                            <Menu.Item><a href="https://roshduni.net/ar/%d8%a7%d8%aa%d9%81%d8%a7%d9%82%d9%8a%d8%a9-%d8%aa%d8%b9%d8%a7%d9%88%d9%86-%d9%85%d8%b9-%d8%ac%d8%a7%d9%85%d8%b9%d8%a9-%d8%a7%d8%b3%d9%8a%d8%a7/">اتفاقية تعاون مع جامعة اسيا</a></Menu.Item>
                            <Menu.Item><a href="https://roshduni.net/ar/%d8%a7%d9%84%d8%aa%d8%b3%d8%ac%d9%8a%d9%84-%d9%81%d9%8a-%d8%a8%d8%b1%d8%a7%d9%85%d8%ac-%d8%ac%d8%a7%d9%85%d8%b9%d8%a9-%d8%a7%d8%b3%d9%8a%d8%a7/">التسجيل في برامج جامعة اسيا</a></Menu.Item>
                        </Menu.SubMenu>
                        <Menu.Item><a href="https://roshduni.net/ar/%d8%ad%d9%88%d9%84-%d8%ac%d8%a7%d9%85%d8%b9%d8%a9-%d8%b5%d8%a8%d8%a7%d8%ad-%d8%a7%d9%84%d8%af%d9%8a%d9%86-%d8%b2%d8%b9%d9%8a%d9%85/">جامعة صباح الدين زعيم</a></Menu.Item>
                    </Menu.SubMenu>
                </Menu.SubMenu>
            </Menu>
        </Layout.Header>
    )
}


export default Header