import React from "react";
import { Row, Card, Col, Table } from "antd";
import { Tabs } from 'antd';
import { AdminApi } from "../../../../../api/AdminApi";
import { ApiConfig } from "../../../../../config/ApiConfig";
import { DepartmentCount } from "../../../../../tos/DepartmentCount";
import { CodeSentCount } from "../../../../../tos/CodeSentCount";
import { EmployerRequestCount } from "../../../../../tos/EmployerRequestCount";
import { BarChart, CartesianGrid, XAxis, YAxis, Bar, Tooltip, Legend, PieChart, Pie } from 'recharts';
import Column from "antd/lib/table/Column";
import { adminWrapperComp } from "../../../../HOC/adminDashboard";

const { TabPane } = Tabs;

export interface IChartState {
  departmentCountTO: DepartmentCount[];
  codeSentCount: CodeSentCount[];
  employerRequestCount: EmployerRequestCount[];
  tnOfDepartments: number,
  tnOfCertificates: number,
  tnOfStudentsRequests: number,
  tnOfEmployerRequests: number
}

export interface IChartProps {

}
const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

class RoshdChart extends React.PureComponent<IChartProps, IChartState>  {

  adminApi = new AdminApi(new ApiConfig({}));
  constructor(props: Readonly<IChartProps>) {
    super(props);
    this.state = {
      codeSentCount: [], employerRequestCount: [], departmentCountTO: [], tnOfDepartments: 0, tnOfCertificates: 0, tnOfStudentsRequests: 0,
      tnOfEmployerRequests: 0
    };
  }


  componentDidMount() {
    this.adminApi.getStatistic().then(
      res => {
        this.setState({ codeSentCount: res.codeSentCounts });
        this.setState({ employerRequestCount: res.employerRequestCounts });
        this.setState({ departmentCountTO: res.departmentCounts });
        this.setTotalNumberOfCertAndDept();
        this.setTotalNumberOfStudentReq();
        this.setTotalNumberOfEmployerReq();
      }
    );
  }

  renderPieChart() {
  }

  setTotalNumberOfEmployerReq = () => {
    let total = 0;
    this.state.employerRequestCount.forEach(x => {
      total = total + x.countReq;
    })
    this.setState({ tnOfEmployerRequests: total });
  }

  setTotalNumberOfCertAndDept = () => {
    let tnOfCertificates = 0;
    let tnOfDepts = 0;
    this.state.departmentCountTO.forEach(x => {
      if (x.department) {
        tnOfDepts = tnOfDepts + 1;
      }
      if (x.countDepartment) {
        tnOfCertificates = tnOfCertificates + x.countDepartment;
      }
    })
    this.setState({ tnOfDepartments: tnOfDepts, tnOfCertificates: tnOfCertificates });
  }

  setTotalNumberOfStudentReq = () => {
    let total = 0;
    this.state.codeSentCount.forEach(x => {
      total = total + x.count;
    })
    this.setState({ tnOfStudentsRequests: total });
  }

  render() {
    let data01: any[] = [];
    let data02: any[] = []
    this.state.codeSentCount.forEach(x => {
      const obj = { name: x.email, value: x.count };
      data01.push(obj);
    })
    this.state.employerRequestCount.forEach(x => {
      const obj = { name: x.certId, value: x.countReq };
      data02.push(obj);
    })

    return (
      <Tabs>
        <TabPane tab="Department Info" key="1">
          <Row style={{ marginTop: 30 }}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} style={{ display: 'flex', justifyContent: 'center' }}>
              <BarChart width={730} height={350} data={this.state.departmentCountTO}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="department" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar name="Number of Certificate in each Department" dataKey="countDepartment" fill="#400120" />
              </BarChart>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} style={{ display: 'flex', justifyContent: 'center', marginTop: 30 }}>
              <Card title="Total number of certificates" hoverable={true}>
                <p style={{ textAlign: 'center', fontWeight: 900, fontSize: 25, color: '#fcb60b' }}>{this.state.tnOfCertificates}</p>
              </Card>
              <Card title="Total number of departments" style={{ marginLeft: 15 }} hoverable={true}>
                <p style={{ textAlign: 'center', fontWeight: 900, fontSize: 25, color: '#fcb60b' }}>{this.state.tnOfDepartments}</p>
              </Card>
            </Col>
          </Row>
        </TabPane>
        <TabPane tab="Student Info" key="2">
          <Row style={{ marginTop: 30 }}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} style={{ display: 'flex', justifyContent: 'space-evenly' }}>
              <PieChart width={300} height={300}>
                <Pie dataKey="value" isAnimationActive={false} data={data01} outerRadius={80} fill="#400120" label />
                <Tooltip />
              </PieChart>
              <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                <Card title="Total number of student requests" hoverable={true} style={{ maxHeight: 150 }}>
                  <p style={{ textAlign: 'center', fontWeight: 900, fontSize: 25, color: '#fcb60b' }}>{this.state.tnOfStudentsRequests}</p>
                </Card>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} style={{ marginTop: 30 }}>
              <h2>Details:</h2>
              <Table
                pagination={false}
                dataSource={this.state.codeSentCount}
              >
                <Column title="Student Email" dataIndex="email" key="email" />
                <Column title="Number of code requests" dataIndex="count" key="count" defaultSortOrder='descend'
                  sorter={(a: any, b: any) => a.count - b.count} />
              </Table>
            </Col>
          </Row>
        </TabPane>
        <TabPane tab="Search for Certificate" key="3">
          <Row style={{ marginTop: 30 }}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} style={{ display: 'flex', justifyContent: 'space-evenly' }}>
              <PieChart width={300} height={300}>
                <Pie dataKey="value" isAnimationActive={false} data={data02} outerRadius={80} fill="#400120" label />
                <Tooltip />
              </PieChart>
              <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                <Card title="Total number of employer requests" hoverable={true} style={{ maxHeight: 150 }}>
                  <p style={{ textAlign: 'center', fontWeight: 900, fontSize: 25, color: '#fcb60b' }}>{this.state.tnOfEmployerRequests}</p>
                </Card>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} style={{ marginTop: 30 }}>
              <h2>Details:</h2>
              <Table
                pagination={false}
                dataSource={this.state.employerRequestCount}>
                <Column title="Certification Id" dataIndex="certId" key="certId" />
                <Column title="Number of certificate requests" dataIndex="countReq" key="countReq" defaultSortOrder='descend'
                  sorter={(a: any, b: any) => a.countReq - b.countReq} />
              </Table>
            </Col>
          </Row>
        </TabPane>
      </Tabs>
    );


  }


}


const roshdChart = adminWrapperComp(RoshdChart, "1");
export { roshdChart as RoshdChart };
