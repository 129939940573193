
export class InternalServerErrorException implements Error {

    name: string;
    message: string;
    stack?: string | undefined;

    constructor(name:string, msg:string){
        this.message = msg;
        this.name = name;
    }

}