

export class DeleteCertTO {

    certificateIds: number[];

    constructor( cerificatesIds : number[]){
        this.certificateIds = cerificatesIds;
    }

}