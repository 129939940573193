import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import 'antd/dist/antd.css';
import './index.css';
import "./roshd-styles.css";
import { HomePage } from './Pages/UserPages/homePage';
import { AdminLoginPage } from './Pages/AdminPages/loginPage';
import { PrivateRoute } from './Components/StatelessComponents/PrivateRoute';
import "./api/interceptor.ts";
import { RoshdChart } from './Components/ComponentsWithStates/Admin/modals/statistic/RoshdChart';
import { UploadCertificate } from './Components/ComponentsWithStates/Admin/modals/upload/uploadCeritificates';
import { RoshdData } from './Components/ComponentsWithStates/Admin/roshdData/roshdData';
import { RoshdArchive } from './Components/ComponentsWithStates/Admin/modals/archive/RoshdArchive';
import { RoshdBackUp } from './Components/ComponentsWithStates/Admin/modals/backup/RoshdBackUp';

const rootElement = document.getElementById("root");

ReactDOM.render(
    <>
        <BrowserRouter key="BrowserRouter">
            <Switch key="Switch">
                <Route exact path='/' component={HomePage} />
                <Route exact path='/login' component={AdminLoginPage} />
                <PrivateRoute exact path='/admin' component={RoshdData} />
                <PrivateRoute exact path="/admin/summary" component={RoshdChart} />
                <PrivateRoute exact path="/admin/certs" component={UploadCertificate} />
                <PrivateRoute exact path="/admin/archive" component={RoshdArchive} />
                <PrivateRoute exact path="/admin/backup" component={RoshdBackUp} />
            </Switch>
        </BrowserRouter>
    </>
    ,
    rootElement);