import React from "react";
import { Table, Button, Row, Col } from "antd";
import Column from "antd/lib/table/Column";
import { CertificateTO } from "../../../tos/CertificateTO";
import { StudentApi } from '../../../api/StudentApi';

export interface IUserTableState {
    isLoading: boolean
}

export interface IUserTableProps {
    certifacate: CertificateTO[];
    email: string;
    code: string;
    isActionButtonAvailable: boolean
}

class UserTable extends React.PureComponent<IUserTableProps, IUserTableState>  {


    constructor(props: Readonly<IUserTableProps>) {
        super(props);
        this.state = { isLoading: false }
    }

    studentApi = new StudentApi({});

    async downLoadCertificate(certificateId: string) {
        this.setState({ isLoading: true });
        console.log(certificateId);
        await this.studentApi.downloadStudentCertificate(this.props.email, this.props.code, certificateId);
        this.setState({ isLoading: false });
    }


    render() {
        return <>
            <div>
                <Table dataSource={this.props.certifacate} id="r_tableData">
                    <Table.Column title="رقم الشهادة" dataIndex="certificateId" key="certificateId" />
                    <Table.Column title="سنة التخرج" dataIndex="graduationYear" key="graduationYear" />
                    <Table.Column title="كلية" dataIndex="department" key="department" />
                    <Table.Column title="البريد الإلكتروني للطالب" dataIndex="email" key="studentEmail" />
                    <Table.Column title="اسم الطالب بالعربية" dataIndex="arabicName" key="arabicName" />
                    <Table.Column title="اسم الطالب بالإنجليزية" dataIndex="name" key="studentName" />
                    {this.props.isActionButtonAvailable === true &&
                        <Table.Column
                            title="-"
                            key="action"
                            render={(recoder: CertificateTO, event: any) => (
                                <Button
                                    loading={this.state.isLoading}
                                    onClick={() => this.downLoadCertificate(recoder.certificateId)}
                                >تحميل الشهادة</Button>
                            )}
                        />
                    }
                </Table>
                <Row id="r_responsiveData">
                    {this.props.certifacate.map((cr, idx) => {
                        return (
                            <>
                                <Col xs={12} sm={12} md={12}>
                                    <p className="title">رقم الشهادة</p>
                                    <p>{cr.certificateId}</p>
                                </Col>
                                <Col xs={12} sm={12} md={12}>
                                    <p className="title">اسم الطالب بالعربية</p>
                                    <p>{cr.arabicName}</p>
                                </Col>
                                <Col xs={12} sm={12} md={12}>
                                    <p className="title">اسم الطالب بالإنجليزية</p>
                                    <p>{cr.name}</p>
                                </Col>
                                <Col xs={12} sm={12} md={12}>
                                    <p className="title">البريد الإلكتروني للطالب</p>
                                    <p>{cr.email}</p>
                                </Col>
                                <Col xs={12} sm={12} md={12}>
                                    <p className="title">كلية</p>
                                    <p>{cr.department}</p>
                                </Col>
                                <Col xs={12} sm={12} md={12}>
                                    <p className="title">سنة التخرج</p>
                                    <p>{cr.graduationYear}</p>
                                </Col>
                                {this.props.isActionButtonAvailable === true &&
                                    <Col xs={24} sm={24} md={24} style={{paddingBottom:10}}>
                                        <p className="title">-</p>
                                        <Button
                                            loading={this.state.isLoading}
                                            onClick={() => this.downLoadCertificate(cr.certificateId)}
                                        >تحميل الشهادة</Button>
                                    </Col>
                                }
                            </>
                        )
                    })}
                </Row>
            </div>
        </>;
    }

}


const userTable = UserTable;
export { userTable as UserTable };
