
import React from 'react';
import { Layout, Row, Col } from 'antd';
import "./Footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faFacebookF, faGooglePlusG, faTwitter } from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
    return (
        <Layout.Footer className="r_footer_wrapper">
            <Row className="r_footerSection r_footer">
                <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6} style={{ textAlign: 'right' }}>
                    <h4>الدعم</h4>
                    <ul>
                        <li><a href="https://roshduni.net/ar/submit-ticket/">تذكرة دعم جديدة</a></li>
                        <li><a href="https://roshduni.net/ar/my-tickets/">صندوق تذاكر الدعم</a></li>
                    </ul>
                </Col>
                <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6} style={{ textAlign: 'right' }}>
                    <h4>روابط</h4>
                    <ul>
                        <li><a href="https://roshduni.net/ar/events/">الاحداث</a></li>
                        <li><a href="https://roshduni.net/ar/faqs/">الأسئلة الشائعة</a></li>
                    </ul>
                </Col>
                <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6} style={{ textAlign: 'right' }}>
                    <h4>حول رشد</h4>
                    <ul>
                        <li><a href="https://roshduni.net/ar/about-us/">حول رشد الافتراضية</a></li>
                        <li><a href="https://roshduni.net/ar/contact/">اتصل بنا</a></li>
                        <li><a href="https://roshduni.net/ar/become-a-teacher/">كن محاضرا</a></li>
                    </ul>
                </Col>
                <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6} style={{ textAlign: 'right' }}>
                    <img src="https://roshduni.net/wp-content/uploads/2018/08/Rosh-Logo-Final1.png" alt="Roshd University" width="145" height="40"
                        style={{ marginBottom: 35 }} />
                    <div style={{ marginBottom: 17 }}>
                        <a href="tel:00908502267176" className="contatInfo">(+90) 850 226 7176</a>
                        <FontAwesomeIcon icon={faPhone} style={{ transform: "rotate(90deg)" }} className="r_footerIcon" />
                    </div>
                    <div>
                        <a href="mailto:info@roshduni.com" className="contatInfo">info@roshduni.com</a>
                        <FontAwesomeIcon icon={faEnvelope} className="r_footerIcon" />
                    </div>
                    <div className="r_footerSocial">
                        <div className="r_footerSocialBtn r_footerSocialBtn_FB">
                            <a href="https://www.facebook.com/Roshduni"><FontAwesomeIcon icon={faFacebookF} /></a>
                        </div>
                        <div className="r_footerSocialBtn r_footerSocialBtn_TW">
                            <a href="https://twitter.com/RoshdUni"><FontAwesomeIcon icon={faTwitter} /></a>
                        </div>
                        {/* <div className="r_footerSocialBtn r_footerSocialBtn_GP">
                            <a><FontAwesomeIcon icon={faGooglePlusG} /></a>
                        </div> */}
                    </div>
                </Col>
            </Row>
            <Row className="r_footerSection r_copyRight">
                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                    <ul>
                        <li><a href="https://roshduni.net/privacy-policy/">الخصوصية</a></li>
                        <li><a href="https://roshduni.net/sales-refund-terms/">الشروط</a></li>
                    </ul>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                    <p style={{textAlign:'right'}}><a href="https://roshduni.com">© </a> 2020 <a href="http://roshduni.net">ROSHD VIRTUAL UNIVERSITY</a>.</p>
                </Col>
            </Row>
        </Layout.Footer>
    )
}


export default Footer