
export class CertificateTO {
    
    id:number;
    certificateId: string;
    department: string;
    email: string;
    graduationYear: string;
    name: string;
    arabicName: string;
    key:string;

    constructor(id: number, certificateId: string, department: string, email: string, graduationYear: string, name: string, arabicName: string) {
        this.id = id;
        this.certificateId = certificateId;
        this.department = department;
        this.email = email;
        this.graduationYear = graduationYear;
        this.name = name; 
        this.arabicName = arabicName;
        this.key = certificateId;
        }   


}