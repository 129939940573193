import { Api } from "./Api";
import BackendApi from '../config/BackendApi.json'
import { AxiosRequestConfig, AxiosResponse } from "axios";
import { LoginTO } from "../tos/LoginTO";
import { UnaothorizedException } from "../exception/UnaothorizedException";
import { ApiConfig } from "../config/ApiConfig";
import LocalStorageService from "./LocalStorageService";
import { CertificateTO } from "../tos/CertificateTO";
import { FilteringCertificateTO } from "../tos/FilteringCertificateTO";
import { CertificateWithPaginationTO } from "../tos/CertificateWithPaginationTO";
import { InternalServerErrorException } from "../exception/InternalServerErrorException";
import { DeleteCertTO } from "../tos/DeleteCertTO";
import { StatisticTO } from "../tos/StatisticTO";
import { ArchiveTO } from "../tos/ArchiveTO";
import { NotFoundException } from "../exception/NotFoundException";
import { NotAllowedException } from "../exception/NotAllowedException";
import { UploadExcelResult } from "../tos/UploadExcelResult";

export class AdminApi extends Api {

    constructor(config: AxiosRequestConfig) {
        super(config, true);
    }

    public login(loginTO: LoginTO): Promise<AxiosResponse> {
        return this.post<LoginTO, any, AxiosResponse<string>>(BackendApi.basicUrl + BackendApi.admin.login, loginTO)
            .then((response) => {
                LocalStorageService.setToken(response.data);
                return response;
            })
            .catch((resError) => {
                throw new UnaothorizedException("Unathorized Exception", "Unathorized Exception");
            });
    }

    public logout(): Promise<AxiosResponse> {
        let token = LocalStorageService.getAccessToken();

        return this.get<void, AxiosResponse<void>>(BackendApi.basicUrl + BackendApi.admin.logout, new ApiConfig({ Authorization: token }))
            .then((response) => {
                LocalStorageService.clearToken();
                return response;
            })
            .catch((resError) => {
                throw new UnaothorizedException("Unathorized Exception", "Unathorized Exception");
            });
    }

    public getCertificates(filteringObj: FilteringCertificateTO): Promise<CertificateWithPaginationTO> {
        let token = LocalStorageService.getAccessToken();

        return this.post<CertificateWithPaginationTO, FilteringCertificateTO, AxiosResponse<CertificateWithPaginationTO>>(BackendApi.basicUrl + BackendApi.admin.certificateSearch, filteringObj, new ApiConfig({ Authorization: token }))
            .then(response => {
                return response.data as CertificateWithPaginationTO;
            })
            .catch((resError) => {
                if (resError.response.status == 401)
                    throw new UnaothorizedException("Unathorized Exception", "Unathorized Exception");
                throw new InternalServerErrorException("SomethingWentWrong", "SomethingWentWrong");
            });
    }

    public getArchive(): Promise<ArchiveTO[]> {
        let token = LocalStorageService.getAccessToken();

        return this.get<any, AxiosResponse<ArchiveTO[]>>(BackendApi.basicUrl + BackendApi.admin.archive, new ApiConfig({ Authorization: token }))
            .then(response => {
                return response.data as ArchiveTO[];
            })
            .catch((resError) => {
                if (resError.response.status == 401)
                    throw new UnaothorizedException("Unathorized Exception", "Unathorized Exception");
                throw new InternalServerErrorException("SomethingWentWrong", "SomethingWentWrong");
            });
    }


    public deleteCertifcates(deleteTO: DeleteCertTO): Promise<AxiosResponse> {
        let token = LocalStorageService.getAccessToken();
        return this.post<DeleteCertTO, any, AxiosResponse<void>>(BackendApi.basicUrl + BackendApi.admin.deleteCertrifcates, deleteTO, new ApiConfig({ Authorization: token }))
            .then((response) => {
                return response;
            })
            .catch((resError) => {
                throw resError;
            });
    }

    public uploadExcelFile(file: any): Promise<UploadExcelResult> {
        let token = LocalStorageService.getAccessToken();
        return this.post<any, any, AxiosResponse<UploadExcelResult>>(BackendApi.basicUrl + BackendApi.admin.importer, file, new ApiConfig({ Authorization: token }))
            .then((response) => response.data)
            .catch((resError) => {
                throw resError;
            });
    }

    public downLoadCertificateAdmin(id:string){
        let token = LocalStorageService.getAccessToken();
        return this.downloadFile( BackendApi.basicUrl + BackendApi.admin.download + id, {"Authorization":token})
    }

    public downloadBakupFile(){
        let token = LocalStorageService.getAccessToken();
        return this.downloadBackup( BackendApi.basicUrl + BackendApi.admin.backup , {"Authorization":token})
    }

    public addNewRecord(to: CertificateTO): Promise<AxiosResponse>{
        let token = LocalStorageService.getAccessToken();
        return this.post<any, any, AxiosResponse<AxiosResponse>>(BackendApi.basicUrl + BackendApi.admin.certifcate, to, new ApiConfig({ Authorization: token }))
        .then((response) => {
            return response;
        })
        .catch((resError) => {
            if (resError.response.status==401){
                throw new UnaothorizedException("Unathorized Exception", "Unathorized Exception");
 
            } else if (resError.response.status == 404){
                throw new NotFoundException("Unathorized Exception", "Unathorized Exception");
            } else if(resError.response.status == 405){
               throw new NotAllowedException("NotAllowed Exception", "NotAllowed Exception");     
            }
            throw resError;
        });
    }

    public editRecord(to: CertificateTO): Promise<AxiosResponse>{
        let token = LocalStorageService.getAccessToken();
        return this.put<any, any, AxiosResponse<AxiosResponse>>(BackendApi.basicUrl + BackendApi.admin.certifcate, to, new ApiConfig({ Authorization: token }))
        .then((response) => {
            return response;
        })
        .catch((resError) => {
            if (resError.response.status==401){
                throw new UnaothorizedException("Unathorized Exception", "Unathorized Exception");
 
            } else if (resError.response.status == 404){
                throw new NotFoundException("Unathorized Exception", "Unathorized Exception");
            } else if(resError.response.status == 405){
               throw new NotAllowedException("NotAllowed Exception", "NotAllowed Exception");     
            }
            throw resError;
        });
    }

    public getStatistic(): Promise<StatisticTO>{
        let token = LocalStorageService.getAccessToken();

        return this.get<any, AxiosResponse<StatisticTO>>(BackendApi.basicUrl + BackendApi.admin.statstic , new ApiConfig({ Authorization: token }))
        .then(res=>  res.data);

    }
}