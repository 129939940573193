import { Api } from "./Api";
import BackendApi from '../config/BackendApi.json'
import { AxiosRequestConfig, AxiosResponse  } from "axios";
import { CertificateTO } from "../tos/CertificateTO";
import {NotFoundException} from '../exception/NotFoundException';
import { InternalServerErrorException } from "../exception/InternalServerErrorException";

export class EmployerApi extends Api {
   
    constructor (config: AxiosRequestConfig) {
        super(config);
    }

    public checkCertificate (certificateId: string): Promise<CertificateTO> {
         return  this.get<void,AxiosResponse<CertificateTO>>(BackendApi.basicUrl+ BackendApi.employer.checkCert + certificateId)
            .then((response)=>
            
                     new CertificateTO(
                        response.data.id,
                        response.data.certificateId,
                        response.data.department,
                        response.data.email,
                        response.data.graduationYear,
                        response.data.name,
                        response.data.arabicName
                        ))
            .catch((resError)=> {
                if (resError.response.status == 404){
                    throw new NotFoundException("NotFound","Could't find certificate with Id ="+ certificateId);
                    
                }
                throw new InternalServerErrorException("NotFound","Could't find certificate with Id ="+ certificateId);

            });
    }
}