import { CertificateTO } from "./CertificateTO";
import { LoginTO } from "./LoginTO";

export class FilteringCertificateTO {

    certificateId: string |  null  = null;
    departments: string[] | null = null;
    email: string | null = null;
    graduationYearStart: number | null = null;
    graduationYearEnd: number | null = null;
    name: string | null = null;
    arabicName: string | null = null;
    pageNumber: number | null = null;
    pageSize: number | null = null;    

    constructor(certificteId: string | null , departments: string[] | null, email: string | null, graduationYearStart: number | null,
        graduationYearEnd: number | null,  name: string | null, arabicName: string | null, pageNumber: number | null,
        pageSize: number | null ){
            this.certificateId = certificteId;
            this.departments = departments;
            this.email = email;
            this.graduationYearStart = graduationYearStart;
            this.graduationYearEnd = graduationYearEnd;
            this.name =name;
            this.arabicName = arabicName;
            this.pageNumber = pageNumber;
            this.pageSize = pageSize;
    }

}