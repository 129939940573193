const LocalStorageService = (function(){
    var _service: any;
    function _getService(this: { getService: () => any; setToken: (tokenObj: any) => void; getAccessToken: () => string | null; 
                                 getRefreshToken: () => string | null; clearToken: () => void;}) {
        if(!_service) {
          _service = this;
          return _service
      }
      return _service
    }
    function _setToken(tokenObj: any) {
      localStorage.setItem('tokenString', tokenObj.token);
      localStorage.setItem('refreshToken', tokenObj.refreshToken);
    }
    function _getAccessToken() {
      return localStorage.getItem('tokenString');
    }
    function _getRefreshToken() {
      return localStorage.getItem('refreshToken');
    }
    function _clearToken() {
      localStorage.removeItem('tokenString');
      localStorage.removeItem('refreshToken');
    }
   return {
      getService : _getService,
      setToken : _setToken,
      getAccessToken : _getAccessToken,
      getRefreshToken : _getRefreshToken,
      clearToken : _clearToken
    }
   })();
   export default LocalStorageService;