import React, { RefObject } from "react";
import { Modal, Form, Input, DatePicker, message } from "antd";
import "./addNewRecord.css";
import { CertificateTO } from "../../../../../tos/CertificateTO";
import { AdminApi } from "../../../../../api/AdminApi";
import { ApiConfig } from "../../../../../config/ApiConfig";
import moment from "moment";
import { FormInstance } from "antd/lib/form";
import { NotAllowedException } from "../../../../../exception/NotAllowedException";
import { NotFoundException } from "../../../../../exception/NotFoundException";
const { RangePicker } = DatePicker;

export interface INewRecordModalState {

}

export interface INewRecordModalProps {
    isItVisible: boolean;
    closeModal: any;
    updateData: any;
    resetSelectedData:any;
    isEditModeActive: boolean,
    certificate?: CertificateTO
}

class NewRecordModal extends React.PureComponent<INewRecordModalProps, INewRecordModalState>  {

    private formRef: RefObject<FormInstance>;

    adminApi = new AdminApi(new ApiConfig({}));

    constructor(props: INewRecordModalProps) {
        super(props);
        this.formRef = React.createRef<FormInstance>();
    }

    

    closeModal = () => {
        this.formRef.current?.resetFields();
        this.props.resetSelectedData();
        this.props.closeModal();
        
    }

    addNewRecord = (cert: CertificateTO) =>{

        if (this.props.isEditModeActive){
            cert.id = this.props.certificate?.id !== undefined? this.props.certificate?.id: 0;
            this.adminApi.editRecord(cert)
            .then(res => {
                if (res.status === 200) {
                    this.props.updateData();                
                    message.success('The record has been updated successfully!');
                    this.closeModal();
                }
            }).catch(err => {
                if(err instanceof NotFoundException){
                    message.error('Couldn\'t find this certificate');
                    this.closeModal();
                    return;
                } else if(err instanceof NotAllowedException) {
                    message.error('This certificate Id is already in use');
                    this.closeModal();
                    return;
                }
                message.error('Something went wrong please try again later!');
                this.closeModal();
            }
            );
          
        } else{ 
        this.adminApi.addNewRecord(cert)
        .then(res => {
            if (res.status === 200) {
                this.props.updateData();                
                message.success('The record has been added successfully!');
                this.closeModal();
            }
        }).catch(err => {
            if(err instanceof NotAllowedException){
                message.error('This Certificate Id already used, pleas check your entry');
                this.closeModal();
                return;
            }
            message.error('Something went wrong please try again later!');
            this.closeModal();
        }
        );
    }
    }
      

    handleForm = (values: any) => {
        let yearV = "" as string;
        if (values && values.graduationYear) {
            const startYear = new Date(values.graduationYear[0]._d).getFullYear().toString();
            const endYear = new Date(values.graduationYear[1]._d).getFullYear().toString();
            yearV = startYear + "-" + endYear;
        }
        let cert = values as CertificateTO;
        cert.graduationYear = yearV;
        this.addNewRecord(cert);
    };

    submitModal = () => {
        this.formRef.current?.submit();
    }

    readDateRang():any{
        const dates = this.props.certificate?.graduationYear?.split("-");
        if (dates) {
            return [moment(dates[0] + '-01-01', 'YYYY-MM-DD'), moment(dates[1] + '-01-01', 'YYYY-MM-DD')];
        }
        return  null;
    }


    render() {
        return <>
            <Modal
                title="Add new entry"
                visible={this.props.isItVisible}
                onOk={this.submitModal}
                onCancel={this.closeModal}
            >
                <Form name="new_record" ref={this.formRef} onFinish={this.handleForm} >
                    <Form.Item name="name"
                    rules={[{ required: true, message: 'Please enter your studet name!' }]}
                    initialValue ={this.props.certificate?.name}
                    >
                        <Input placeholder="Student Name(En)" />
                    </Form.Item>
                    <Form.Item name="arabicName"
                        rules={[{ required: true, message: 'Please enter your studet name in Arabic!' }]}
                        initialValue ={this.props.certificate?.arabicName}
                    >
                        <Input placeholder="Student Name(Ar)" />
                    </Form.Item>
                    <Form.Item name="email"
                        rules={[{ required: true, type:"email",  message: 'Please enter your studet email' }]}
                        initialValue ={this.props.certificate?.email}
                    >
                        <Input placeholder="Student Email" />
                    </Form.Item>
                    <Form.Item name="department"
                        rules={[{ required: true, message: 'Please enter department!' }]}
                        initialValue ={this.props.certificate?.department}
                    >
                        <Input placeholder="Department" />
                    </Form.Item>
                    <Form.Item name="graduationYear"
                        rules={[{ required: true, message: 'Please select graduation year!' }]}
                        initialValue ={this.readDateRang()}
                    >
                        <RangePicker picker="year" style={{ width: '100%' }} />
                    </Form.Item>
                    <Form.Item name="certificateId"
                        rules={[{ required: true, message: 'Please select certificateId!' }]}
                        initialValue ={this.props.certificate?.certificateId}
                    >
                        <Input placeholder="Certificate ID" defaultValue={this.props.certificate?.certificateId}/>
                    </Form.Item>
                </Form>
            </Modal>
        </>;
    }

}


const newRecordModal = NewRecordModal;
export { newRecordModal as NewRecordModal };
