import { Api } from "./Api";
import BackendApi from '../config/BackendApi.json'
import { AxiosRequestConfig, AxiosResponse } from "axios";
import { NotFoundException } from '../exception/NotFoundException';
import { GetCodeTO } from "../tos/GetCodeTO";
import { InternalServerErrorException } from "../exception/InternalServerErrorException";
import { CertificateTO } from "../tos/CertificateTO";
import { UnaothorizedException } from "../exception/UnaothorizedException";

export class StudentApi extends Api {

    constructor(axiosConfig: AxiosRequestConfig) {
        super(axiosConfig);
    }

    public getCode(email: string): Promise<void> {
        let getCodeTO = new GetCodeTO(email)
        return this.post<void, GetCodeTO, AxiosResponse<void>>(BackendApi.basicUrl + BackendApi.student.getCode, getCodeTO)
            .then(response =>
                console.log("Email Sent successfully")
            ).catch(err => {
                if (err.response.status == 404) {
                    throw new NotFoundException("NotFoundException", "Couldn't find email");
                } else {
                    throw new InternalServerErrorException("InternalServerError", "Something went wrong");
                }
            })
    }


    public getStudentCertificates(email: string, code: string): Promise<CertificateTO[]> {
        let basicAuth = btoa(email + ":" + code);
        this.axiosConfig.headers = { "StudentAuthorization": basicAuth };
        return this.get<void, AxiosResponse<CertificateTO[]>>(BackendApi.basicUrl + BackendApi.student.studentCertificate, this.axiosConfig)
            .then(response => response.data as CertificateTO[]
            ).catch(errorRes => {
                if (errorRes.response.status == 404) {
                    throw new NotFoundException("NotFoundException", "Couldn't find email");
                } else if (errorRes.response.status == 401) {
                    throw new UnaothorizedException("UnaothorizedException", "Please check your email and code, the code is valid only for 30 min");
                }
                else {
                    throw new InternalServerErrorException("InternalServerError", "Something went wrong");
                }

            })
    }

    public async downloadStudentCertificate(email: string, code: string, certificateId: string) {
        let basicAuth = btoa(email + ":" + code);
        await this.downloadFile(BackendApi.basicUrl + BackendApi.student.studentCertificate + "/" + certificateId, { "StudentAuthorization": basicAuth })
    }
}