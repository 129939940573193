import React from "react";
import { Modal } from "antd";
import { Upload, message } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { AdminApi } from "../../../../../api/AdminApi";
import { ApiConfig } from "../../../../../config/ApiConfig";
import BackendApi from '../../../../../config/BackendApi.json'
import LocalStorageService from "../../../../../api/LocalStorageService";
import { UploadExcelResult } from "../../../../../tos/UploadExcelResult";
import { UploadResultTable } from "../addNewRecord/UploadResultTable";

const { Dragger } = Upload;


export interface IExcelModalState {
    showResultTable:boolean;
    failedIds:string[];
}

export interface IExcelModalProps {
    isItVisible: boolean;
    closeModal: any;
}

class ExcelModal extends React.PureComponent<IExcelModalProps, IExcelModalState>  {

    adminApi = new AdminApi(new ApiConfig({}));
    constructor(props: Readonly<IExcelModalProps>) {
        super(props);
        this.state={showResultTable:false, failedIds:[]}
    }

    closeModal = () => {
        this.setState({showResultTable:false,failedIds:[]});
        this.props.closeModal();
    }

    sendExcelFile(info:any){
        {
            const { status } = info.file;
            if (status !== 'uploading') {

            }
            if (status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`);
                this.setState({showResultTable:false,failedIds:[]});
                this.props.closeModal();
            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
    }
}

    getToken(){
        let token = LocalStorageService.getAccessToken()
        return  token == null ?'':token;
    }

    uploadFileRes= (res:UploadExcelResult)=>{
        if(res.failedIds.length !==0){
            this.setState({failedIds:res.failedIds});
            this.setState({showResultTable:true});
        } else {
            message.success('file uploaded successfully.');
            this.props.closeModal();
        }
    }

    dragProps = {
        accept:'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        name: 'file',
        multiple: false,
        action: BackendApi.basicUrl + BackendApi.admin.importer ,
        showUploadList	:{showPreviewIcon:false, showRemoveIcon:false, showDownloadIcon:false},
        defaultFileList:[],
        headers:{"Authorization": this.getToken()},
        onSuccess:(res:UploadExcelResult)=>{this.uploadFileRes(res)},
        onChange: this.sendExcelFile.bind(this),
        
        };

    render() {
        return <>
            <Modal
                title="Excel Entries"
                visible={this.props.isItVisible}
                onOk={this.closeModal}
                onCancel={this.closeModal}
            >
                {this.props.isItVisible && !this.state.showResultTable && 
                <Dragger {...this.dragProps}>
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Upload Excel file here</p>
                    <p className="ant-upload-hint">
                        Support for a single or bulk upload. Strictly prohibit from uploading company data or other
                        band files
                    </p>
                </Dragger>}
                {this.state.showResultTable && 
                
                <UploadResultTable certificateIds={this.state.failedIds} />
                }
            </Modal>
        </>;
    }

}


const excelModal = ExcelModal;
export { excelModal as ExcelModal };
