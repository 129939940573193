import React from "react";
import { Form, Input, Button, Spin } from "antd";
import { UserTable } from "../../../StatelessComponents/UserTable/UserTable";
import { CertificateTO } from "../../../../tos/CertificateTO";
import { EmployerApi } from "../../../../api/EmployerApi";
import { ApiConfig } from '../../../../config/ApiConfig';
import { NotFoundException } from "../../../../exception/NotFoundException";

export interface IEmployerUIState {
    isTableVisible: boolean;
    isLoading: boolean;
    certifacate: CertificateTO | any;
    errorMessage: string;
}

export interface IEmployerUIProps {
}

class EmployerUI extends React.PureComponent<IEmployerUIProps, IEmployerUIState>  {

    employerApi = new EmployerApi({});

    constructor(props: Readonly<IEmployerUIProps>) {
        super(props);
        this.state = { isTableVisible: false, isLoading: false, certifacate: undefined, errorMessage: "" }
    }

    onFinish = (values: any) => {
        let certificateID = values.certificateID;
        this.setState({ isLoading: true });
        this.setState({ errorMessage: "" });
        this.employerApi.checkCertificate(certificateID).then(cert => {
            this.setState({ certifacate: cert });
            this.setState({ isLoading: false });
            this.setState({ isTableVisible: true });
        }).catch(err => {
            this.setState({ isLoading: false });
            this.setState({ isTableVisible: false });
            if (err instanceof NotFoundException) {
                this.setState({ errorMessage: 'لا يمكن العثور على هذه الشهادة' });
                return;
            }
            this.setState({ errorMessage: 'Something went wrong' });
        })
    };

    renderSearchResult() {
        if (this.state.isLoading) {
            return (
                <div style={{ textAlign: 'center' }}>
                    <span>
                        <Spin size="large" />
                    </span>
                </div>
            );
        }
        if (this.state.isTableVisible) {
            return <UserTable
                email=''
                code=''
                certifacate={[this.state.certifacate]}
                isActionButtonAvailable={false} />
        }
        if (this.state.errorMessage !== "") {
            return (
                <div style={{ textAlign: 'center' }}>
                    <span> <h4> {this.state.errorMessage}</h4> </span>
                </div>
            )
        }
    }

    render() {
        return <>
            <div>
                <Form name="employer_search" onFinish={this.onFinish} style={{ paddingRight: '20%', paddingLeft: '20%', textAlign: 'right' }}>
                    <span className="r_userInputSpan">:يرجى ادخال رقم الشهادة</span>
                    <Form.Item name="certificateID" rules={[{ required: true, message: 'يرجى ادخال رقم الشهادة' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item >
                        <div style={{textAlign:'center'}}>
                            <Button type="primary" htmlType="submit">
                                بحث
                            </Button>
                        </div>
                    </Form.Item>
                </Form>
                {this.renderSearchResult()}
            </div>
        </>;
    }

}


const employerUI = EmployerUI;
export { employerUI as EmployerUI };
