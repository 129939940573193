import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { InternalServerErrorException } from "../exception/InternalServerErrorException";
import { interceptorOverride } from "./interceptor";

export class Api {
    [x:string]: any;
    axiosConfig: AxiosRequestConfig;
    public constructor (axiosConfig: AxiosRequestConfig, isInterceptorRequired: boolean = false) {
        this.axiosConfig = axiosConfig;
        this.api = axios.create(axiosConfig);
        this.get = this.get.bind(this);
        this.delete = this.delete.bind(this);
        this.post = this.post.bind(this);
        if(isInterceptorRequired){
            interceptorOverride(this.api.interceptors);
        }
    }

    public get<T, R = AxiosResponse<T>> (url: string, config?: AxiosRequestConfig): Promise<R> {
        return this.api.get(url, config == undefined? this.axiosConfig : config);
    }


    public delete<T, B, R = AxiosResponse<T>>  (url: string, data?: B, config?: AxiosRequestConfig): Promise<R>{
        return this.api.delete(url, data, config == undefined? this.axiosConfig : config);
    }

    public post<T, B, R = AxiosResponse<T>> (url: string, data?: B, config?: AxiosRequestConfig): Promise<R> {
        return this.api.post(url, data, config == undefined? this.axiosConfig : config);
    }

    public put<T, B, R = AxiosResponse<T>> (url: string, data?: B, config?: AxiosRequestConfig): Promise<R> {
        return this.api.put(url, data, config == undefined? this.axiosConfig : config);
    }
 
    public async downloadFile(url: string, header:any){
        axios({
            url: url, 
            method: 'GET',
            responseType: 'blob',
            headers:header
          }).then((response) => {
             const url = window.URL.createObjectURL(new Blob([response.data]));
             const link = document.createElement('a');
             link.href = url;
             link.setAttribute('download', 'file.pdf'); //or any other extension
             document.body.appendChild(link);
             link.click();
          }).catch(err=>
            console.log(err)
            );
    }

    public async downloadBackup(url: string, header:any){
        axios({
            url: url, 
            method: 'GET',
            responseType: 'blob',
            headers:header
          }).then((response) => {
            if(response.status!=200){
                throw new InternalServerErrorException("couldn't download backup","error")
            }
             const url = window.URL.createObjectURL(new Blob([response.data]));
             const link = document.createElement('a');
             link.href = url;
             link.setAttribute('download', 'backUp.xls'); //or any other extension
             document.body.appendChild(link);
             link.click();
          }).catch(err=>{
            throw new InternalServerErrorException("couldn't download backup","error")
          });
    }

    public success<T> (response: AxiosResponse<T>): T {
        return response.data;
    }

    public error (error: AxiosError<Error>) {
        throw error;
    }

}