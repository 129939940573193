import React from "react";
import { Layout, Row, Col, Form, Input, Button } from "antd";
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import Password from "antd/lib/input/Password";
import { AdminApi } from "../../api/AdminApi";
import { ApiConfig } from "../../config/ApiConfig";
import { LoginTO } from "../../tos/LoginTO";
import LocalStorageService from "../../api/LocalStorageService";


export interface IAdminLoginPageState {
}

export interface IAdminLoginPageProps {
    location: any;
    history:any;
}

class AdminLoginPage extends React.PureComponent<IAdminLoginPageProps, IAdminLoginPageState>  {

    constructor(props: Readonly<IAdminLoginPageProps>){
        super(props);
        if(LocalStorageService.getAccessToken() !== null){
            this.props.history.push("/admin");
        }
    }

    adminApi = new AdminApi(new ApiConfig({}));

    formSubmit = (values: any) => {
        const that = this;
        try {
            this.adminApi.login(new LoginTO(values.username, values.password)).then(function (res) {
                if(res.status === 200){
                    that.props.history.push("/admin");
                }
            }, function (err) {

            });
        }
        catch(err){

        }
    }

    render() {
        return <>
            <Layout className="layout">
                <Layout.Content style={{ padding: '0 50px', height: '100vh' }}>
                    <div className="site-layout-content">
                        <Row>
                            <Col xs={0} sm={0} md={8} lg={9} xl={9} xxl={9}></Col>
                            <Col xs={24} sm={24} md={8} lg={6} xl={6} xxl={6} style={{ textAlign: 'center' }}>
                                <img src="https://roshduni.net/wp-content/uploads/2018/08/Rosh-Logo-Final-LR2.png" width="50%" style={{ marginBottom: '10%', marginTop: '45%' }} />
                                <Form name="login_form" onFinish={this.formSubmit}>
                                    <Form.Item name="username">
                                        <Input placeholder="e-mail address" prefix={<UserOutlined />} size="large" />
                                    </Form.Item>
                                    <Form.Item name="password">
                                        <Password name="password" placeholder="password" prefix={<LockOutlined />} size="large" />
                                    </Form.Item>
                                    <Form.Item>
                                        <Button type="primary" htmlType="submit" size="large">
                                            Login
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </Col>
                            <Col xs={0} sm={0} md={8} lg={9} xl={9} xxl={9}></Col>
                        </Row>
                    </div>
                </Layout.Content>
            </Layout>
        </>;
    }

}


const adminLoginPage = AdminLoginPage;
export { adminLoginPage as AdminLoginPage };
