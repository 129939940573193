import React from "react";
import { Row, Card, Col, Spin, Button, message } from "antd";
import { Tabs } from 'antd';
import { AdminApi } from "../../../../../api/AdminApi";
import { ApiConfig } from "../../../../../config/ApiConfig";
import { adminWrapperComp } from "../../../../HOC/adminDashboard";
import Column from "antd/lib/table/Column";

const { TabPane } = Tabs;

export interface IRoshdBackUptateState {
  loading: Boolean;
}

export interface IRoshdBackUpProps {

}

class RoshdBackUp extends React.PureComponent<IRoshdBackUpProps, IRoshdBackUptateState>  {

  adminApi = new AdminApi(new ApiConfig({}));
  constructor(props: Readonly<IRoshdBackUptateState>) {
    super(props);
    this.setState({ loading: false });
  }


  downloadBackUp() {
    this.setState({ loading: true });
    this.adminApi.downloadBakupFile().then(res =>
      this.setState({ loading: false })
    ).catch(err => {

      message.error("couldn't download your file")
      this.setState({ loading: false })
    });
  }

  showSpinner(){

    return  <Button onClick={this.downloadBackUp.bind(this)} >Download Backup</Button>

  }

  showButton() {
    if (this.state.loading==true) {
      return <Spin size='large' />
    } else {
      return  <Button onClick={this.downloadBackUp.bind(this)} >Download Backup</Button>
    }
  }
  render() {


    return (
      <Row  justify="center" align="middle">
          <Col  flex="1" xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
          <Col  flex="1" xs={16} sm={16} md={16} lg={16} xl={16} xxl={16}>
          {this.showSpinner()}
      
          </Col>
          <Col  flex="1" xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
          </ Col>
          </Col>
      </Row>
    );


  }


}


const roshdBackUp = adminWrapperComp(RoshdBackUp, "5");
export { roshdBackUp as RoshdBackUp };
